import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectConfigurations } from "../../store/configurations/configurations.selector";
import { selectGroups } from "../../store/groups/groups.selector";
import {
  selectEnhancedUser,
  selectUserGroups,
} from "../../store/user/user.selector";
import useDebounce, { alphanumericSort } from "../../utils/helpers/helpers";
import {
  warningConfigurationNoConfig,
  warningConfigurationNoConfigUser,
  warningConfigurationNoGroup,
  warningConfigurationNoResults,
} from "../../utils/helpers/warnings";
import { USER_ROLE } from "../../utils/helpers/constants";

import { Box } from "@mui/material";
import List from "@mui/material/List";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ConfigurationListItem from "../../components/configuration-list/configuration-list-item.component";
import ConfigurationAddDialog from "../../components/configuration-list/configuration-add-dialog.component";
import FloatingActionButton from "../../components/floating-action-button/floating-action-button.component";
import ConfigurationSearchBar from "../../components/configuration-list/configuration-search-bar";
import { selectApps } from "../../store/apps/apps.selector";
import { selectFeatures } from "../../store/features/features.selector";
import { selectLanguages } from "../../store/languages/languages.selector";

const Configurations = () => {
  const configurations = useSelector(selectConfigurations);
  const groups = useSelector(selectGroups);
  const userGroups = useSelector(selectUserGroups);
  const user = useSelector(selectEnhancedUser);
  const apps = useSelector(selectApps);
  const features = useSelector(selectFeatures);
  const languages = useSelector(selectLanguages);

  const isAdmin = user?.role === USER_ROLE.ADMIN || false;

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [query, setQuery] = useState(localStorage.getItem("searchQuery") || "");
  const debouncedQuery = useDebounce(query, 300);

  const [selectedGroup, setSelectedGroup] = useState(
    localStorage.getItem("currentGroupId") || ""
  );

  const [selectedPlatform, setSelectedPlatform] = useState(
    JSON.parse(localStorage.getItem("selectedPlatform")) || []
  );
  const [selectedScreenSize, setSelectedScreenSize] = useState(
    JSON.parse(localStorage.getItem("selectedScreenSize")) || []
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    JSON.parse(localStorage.getItem("selectedLanguage")) || []
  );
  const [selectedFeature, setSelectedFeature] = useState(
    JSON.parse(localStorage.getItem("selectedFeature")) || []
  );

  const [selectedApp, setSelectedApp] = useState(
    JSON.parse(localStorage.getItem("selectedApps")) || []
  );

  useEffect(() => {
    if (selectedGroup) {
      localStorage.setItem("currentGroupId", selectedGroup);
    }
    console.log(configurations, "configurations");
  }, [selectedGroup]);

  useEffect(() => {
    localStorage.setItem("searchQuery", query);
  }, [query]);

  const openAddDialog = () => {
    if (isAdmin) setIsAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const handleSearch = (value) => {
    setQuery(value);
  };

  const handleGroups = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    localStorage.setItem('selectedPlatform', JSON.stringify(platform))
  };

  const handleScreenSizeChange = (screenSize) => {
    const screenSizeValues = screenSize.map(screen => screen.value)
    setSelectedScreenSize([...screenSizeValues])
    console.log(screenSize)
    localStorage.setItem('selectedScreenSize', JSON.stringify(screenSizeValues))
  };

  const handleLanguageChange = (languages) => {
    const languagesId = languages.map(language => language.id)
    console.log(languagesId)
    setSelectedLanguage([...languagesId])
    localStorage.setItem('selectedLanguage', JSON.stringify(languagesId))
  };

  const handleFeatureChange = (features) => {
    const featuresId = features.map(feature => feature.id)
    setSelectedFeature([...featuresId])
    localStorage.setItem('selectedFeature', JSON.stringify(featuresId))
  };

  const handleAppChange = (apps) => {
    const appsId = apps.map(app => app.id)
    setSelectedApp([...appsId]);
    localStorage.setItem("selectedApp", JSON.stringify(appsId));
  };

  const filteredConfig = useMemo(() => {
    return configurations
      .filter((item) => {
        const nameMatches = item.configurationName
          .toLowerCase()
          .includes(debouncedQuery.toLowerCase());
        const selectedGroupObj = groups.find(
          (group) => group.id === selectedGroup
        );
        const groupMatches =
          selectedGroupObj && selectedGroupObj.id === item.groupId;
        const platformMatches =
          !selectedPlatform.length || selectedPlatform.includes(item.platform);
        const screenMatches =
          !selectedScreenSize.length ||
          selectedScreenSize.includes(item.screen);
        const languageMatches =
          !selectedLanguage.length ||
          selectedLanguage.some((lang) => item.languageIds.includes(lang));
        const featureMatches =
          !selectedFeature.length ||
          selectedFeature.some((feature) =>
            item.enabledFeatureIds.includes(feature)
          );
        const appMatches = 
          !selectedApp.length ||
          selectedApp.some(appId => 
            item.enabledAppIds.includes(appId))

        return (
          nameMatches &&
          groupMatches &&
          platformMatches &&
          screenMatches &&
          languageMatches &&
          featureMatches &&
          appMatches
        );
      })
      .filter((item) => item.configurationName)
      .sort((a, b) => {
        const nameA = a.configurationName || "";
        const nameB = b.configurationName || "";
        return nameA.localeCompare(nameB);
      });
  }, [
    configurations,
    debouncedQuery,
    selectedGroup,
    groups,
    selectedPlatform,
    selectedScreenSize,
    selectedLanguage,
    selectedFeature,
    selectedApp,
    isAdmin,
  ]);

  const numberOfConfigurations = `${configurations.length} configurations`;

  const screenSizes = useMemo(() => {
    const filteredConfigurations = configurations.filter((config) => {
      if (isAdmin) {
        return true;
      }
      return config.userGroups.some((group) => userGroups.includes(group));
    });

    const sizes = filteredConfigurations
      .map((config) => config.screen)
      .filter(Boolean);

    const sortedSizes = [...new Set(sizes)]
      .map((size) => {
        const number = parseInt(size, 10);
        return { size, number };
      })
      .sort((a, b) => a.number - b.number) // Сортуємо за числовим значенням
      .map((item) => item.size);

    return sortedSizes;
  }, [configurations, isAdmin, userGroups]);

  const warning = useMemo(() => {
    if (groups.length === 0) return null;

    const userGroups = groups.filter((group) =>
      group.userEmails.includes(user.email)
    );

    if (isAdmin && configurations.length === 0) {
      return warningConfigurationNoConfig;
    } else if (
      !isAdmin &&
      userGroups.length > 0 &&
      configurations.length === 0
    ) {
      return warningConfigurationNoConfigUser;
    } else if (!isAdmin && userGroups.length === 0) {
      return warningConfigurationNoGroup;
    } else if (filteredConfig.length === 0) {
      return warningConfigurationNoResults;
    }
    return null;
  }, [
    groups,
    isAdmin,
    configurations.length,
    filteredConfig.length,
    user.email,
  ]);

  return (
    <Fragment>
      <Container>
        <Stack spacing={4} marginTop={4} marginBottom={8}>
          <Typography variant="h5" textAlign="center">
            Configurations
          </Typography>
          <ConfigurationSearchBar
            handleSearch={handleSearch}
            query={query}
            selectedGroup={selectedGroup}
            allGroups={groups}
            handleGroups={handleGroups}
            selectedPlatform={selectedPlatform}
            handlePlatformChange={handlePlatformChange}
            selectedScreenSize={selectedScreenSize}
            handleScreenSizeChange={handleScreenSizeChange}
            selectedLanguage={selectedLanguage}
            handleLanguageChange={handleLanguageChange}
            selectedFeature={selectedFeature}
            handleFeatureChange={handleFeatureChange}
            selectedApp={selectedApp}
            handleAppChange={handleAppChange}
            filteredConfig={filteredConfig}
            numberOfConfigurations={numberOfConfigurations}
            screenSizes={screenSizes}
            apps={apps}
            features={features}
            languages={languages}
          />
          <Box
            display="flex"
            position="relative"
            justifyContent="flex-end"
            sx={{ m: 0, p: 1 }}
          >
            <Typography color="grey" position="absolute" right="0" bottom="0">
              {filteredConfig.length} of {numberOfConfigurations}
            </Typography>
          </Box>
          <List>
            {warning ? (
              <Typography textAlign="center">{warning}</Typography>
            ) : (
              filteredConfig.map((configuration, idx) => (
                <ConfigurationListItem
                  key={idx}
                  configuration={configuration}
                />
              ))
            )}
          </List>
        </Stack>
      </Container>
      {isAdmin && (
        <FloatingActionButton handleClick={openAddDialog}>
          <AddIcon />
        </FloatingActionButton>
      )}
      <ConfigurationAddDialog
        open={isAddDialogOpen}
        handleClose={closeAddDialog}
      />
    </Fragment>
  );
};

export default Configurations;
