import { FormControl } from "@mui/material";
import React from "react";
import FormikAutocomplete from "../formik-autocomplete/formik-autocomplete.component";

export const FilterSelector = ({
  setFilterOptions,
  avalibleOptions,
  filterName,
  fieldName,
  multiple,
  getLabel = (option) => option,
  getValue = (option) => option, 
}) => {
  return (
    <FormControl fullWidth={true}>
      <FormikAutocomplete
        name={fieldName}
        options={
          avalibleOptions.length === 0 ? [`${filterName}`] : avalibleOptions
        }
        label={filterName}
        multiple={multiple}
        handleGroups={setFilterOptions}
        getLabel={getLabel}
        getValue={getValue}
      />
    </FormControl>
  );
};
