import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { updateEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import { selectConfigurations } from "../../store/configurations/configurations.selector";
import { selectLicenses } from "../../store/licenses/licenses.selector";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikDateField from "../formik-date/formik-date-picker.component";
import { selectEnhancedUser } from "../../store/user/user.selector";
import { selectUsers } from "../../store/users/users.selector";
import dayjs from "dayjs";
import FormikSelect from "../formik-select/select.component";
import { useEffect, useRef } from "react";
import { selectGroups } from "../../store/groups/groups.selector";
import { LICENSE_TYPES, USER_ROLE } from "../../utils/helpers/constants";
import { selectLicenseOverviewGroupId } from "../../store/license-overview/license-overview.selector";
import { getAuth } from "firebase/auth";
import {
  formatDate,
  formatTimestamp,
  sendEmailHelper,
} from "../../utils/helpers/helpers";
import { Timestamp } from "firebase/firestore";

const LicenseEditDialog = ({ open, handleClose, license }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef();
  const allConfiguration = useSelector(selectConfigurations);
  const allGroups = useSelector(selectGroups);
  const users = useSelector(selectUsers);
  const allLicenses = useSelector(selectLicenses);

  const currentLicenseName = license.licenseName;
  const currentLicenceKey = license.licenseKey
  const currentLicenceType = license.licenseType
  const currentLicenceConfigurationName = license.configurationName
  const currentLicenceValidFrom = license.validFrom
  const currentLicenceValidTill = license.validTill
  const currentLicenceComment = license.comment
  const currentLicenceAdminComment = license.adminComment

  // User
  const user = useSelector(selectEnhancedUser);

  // Admin Users
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  const isAdmin = user && user.role === USER_ROLE.ADMIN;

  // License Types
  const licenseTypes = [
    { label: LICENSE_TYPES.TRIAL, value: 0 },
    { label: LICENSE_TYPES.SUBSCRIPTION, value: 1 },
    { label: LICENSE_TYPES.LIFETIME, value: 2 },
  ];

  // Custom sorting function
  const sortLicenseTypes = (a, b) => {
    const order = { Trial: 0, Subscription: 1, Lifetime: 2 };
    return order[a.label] - order[b.label];
  };

  // Sort the licenseTypes array
  const licenseOrder = licenseTypes.sort(sortLicenseTypes);
  const selectedGroupId = useSelector(selectLicenseOverviewGroupId);

  //avalible config
  const configurationsForSelectedGroup = allConfiguration
    .filter((config) => config.groupId === selectedGroupId)
    .map((item) => item.configurationName);

  //assign config
  const assignConfigForLicense = [
    allConfiguration.find((config) => config.id === license.configurationId)
      .configurationName,
  ];

  const avalibleConfigIds = allConfiguration
    .filter((config) => config.groupId === selectedGroupId)
    .map((config) => config.id);

  const allLicensesForSelectedGroup = allLicenses.filter((license) =>
    avalibleConfigIds.includes(license.configurationId)
  );

  const handleSubmit = async (values, { resetForm }) => {
    const auth = getAuth();
    const lastChangedBy = auth.currentUser ? auth.currentUser.email : "unknown";

    const {
      licenseName,
      licenseKey,
      comment,
      licenseType,
      validTill,
      licenseConfigurationName,
      validFrom,
      adminComment,
    } = values;

    // Configuration
    const configurationId = allConfiguration
      .filter((config) => config.groupId === selectedGroupId)
      .find(
        (config) => config.configurationName === licenseConfigurationName
      ).id;

    //Type name normalize
    const normalizeTypeName = licenseTypes.find(
      (item) => item.value === licenseType
    ).label;

    const updatedAt = new Date();
    const validTillDate =
      normalizeTypeName === LICENSE_TYPES.LIFETIME
        ? null
        : Timestamp.fromDate(dayjs(validTill).toDate());
    const validFromDate = Timestamp.fromDate(dayjs(validFrom).toDate());
    const normalizeKey = licenseKey.toUpperCase();

    const isChanged =
      adminComment !== license.adminComment ||
      licenseName !== license.licenseName ||
      normalizeKey !== license.licenseKey ||
      comment.trim() !== license.comment ||
      configurationId !== license.configurationId ||
      normalizeTypeName !== license.type ||
      (validTillDate &&
        (!license.validTill ||
          validTillDate.seconds !== license.validTill.seconds ||
          validTillDate.nanoseconds !== license.validTill.nanoseconds)) ||
      (validFromDate &&
        (!license.validFrom ||
          validFromDate.seconds !== license.validFrom.seconds ||
          validFromDate.nanoseconds !== license.validFrom.nanoseconds));

    if (!isChanged) {
      handleClose();
      resetForm();
      return;
    }

    // Close the dialog immediately
    handleClose();

    try {
      await updateEntry(FIREBASE_COLLECTION_NAMES.LICENSES, license.id, {
        licenseKey: normalizeKey,
        lastChangedBy,
        licenseName,
        updatedAt,
        comment: comment.trim(),
        configurationId,
        type: normalizeTypeName,
        validTill: validTillDate,
        validFrom: validFromDate,
        adminComment: adminComment.trim(),
      });

      /// EMAILER START ///
      const selectedGroup = allGroups.find(
        (group) => group.id === selectedGroupId
      );

      if (!selectedGroup) {
        console.error("Selected group not found");
        return;
      }

      const usersGroupEmails = selectedGroup.userEmails;
      const usersEmails = users
        .filter(
          (user) =>
            usersGroupEmails.includes(user.email) &&
            user.enableNotification === true
        )
        .map((item) => item.notificationEmail);

      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });

      const licenseValidDate =
        licenseType === LICENSE_TYPES.LIFETIME
          ? null
          : dayjs(validTill).toDate();

      const licenseValidFromDate = dayjs(validFrom).toDate();

      const subject = `"${selectedGroup.groupName}" "${licenseName}" License Edited`;

      const textStandardUsers =
        `The license "${licenseName}" got edited by ${auth.currentUser.email} at ${formattedDate}.\n\n` +
        `License Name: ${licenseName}  ${
          currentLicenseName !== licenseName
            ? `(previously: ${currentLicenseName})`
            : ""
        }\n` +
        `License Key: ${licenseKey}  ${
            currentLicenceKey !== licenseKey
                ? `(previously: ${currentLicenceKey})`
                : ""
        }\n` +
        `Type: ${normalizeTypeName}}  ${
            currentLicenceType !== licenseType
                ? `(previously: ${currentLicenceType})`
                : ""
        }\n` +
        `Configuration Name: ${licenseConfigurationName}}  ${
            currentLicenceConfigurationName !== licenseConfigurationName
                ? `(previously: ${currentLicenceConfigurationName})`
                : ""
        }\n` +
        `Valid From: ${formatDate(licenseValidFromDate)}}  ${
            formatDate(currentLicenceValidFrom) !== formatDate(licenseValidFromDate)
                ? `(previously: ${formatDate(currentLicenceValidFrom)})`
                : ""
        }\n` +
        `Valid Till: ${
          normalizeTypeName === LICENSE_TYPES.LIFETIME
            ? "N/A"
            : formatDate(licenseValidDate)
        }}  ${
            currentLicenceValidTill !== licenseValidDate
                ? `(previously: ${formatDate(currentLicenceValidTill)})`
                : ""
        }\n` +
        `Comment: ${comment.trim()}}  ${
            currentLicenceComment !== comment
                ? `(previously: ${currentLicenceComment.trim()})`
                : ""
        }\n`;

      const textAdminUsers =
        textStandardUsers + `Admin Comment: ${adminComment.trim()}  ${
              currentLicenceAdminComment !== adminComment
                  ? `(previously: ${currentLicenceAdminComment.trim()})`
                  : ""
          }\n`;

      const filteredUserEmails = usersEmails.filter(
        (email) => !adminEmails.includes(email)
      );

      const emailDataStandardUsers = {
        to: filteredUserEmails,
        subject,
        text: textStandardUsers,
      };

      const emailDataAdminUsers = {
        to: adminEmails,
        subject,
        text: textAdminUsers,
      };

      // Send emails
      if (filteredUserEmails.length > 0) {
        sendEmailHelper(emailDataStandardUsers);
      }

      if (emailDataAdminUsers.to.length > 0) {
        sendEmailHelper(emailDataAdminUsers);
      }
      /// EMAILER END ///

      enqueueSnackbar(`License "${licenseName}"  was successfully edited.`, {
        variant: "success",
      });

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to edit the license "${licenseName}". Please try again.`,
        { variant: "error" }
      );
      console.log("editing license failed: ", error);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    licenseName: Yup.string()
      .required("License Name is required")
      .test("is-unique-license-name", "Already used", (value) => {
        return allLicensesForSelectedGroup.every(
          (license) =>
            license.licenseName !== value ||
            license.licenseName === currentLicenseName
        );
      }),
    licenseKey: Yup.string()
      .required("License Key is required")
      .matches(
        /^[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/,
        "Required format: XXXX-XXXX-XXXX-XXXX"
      )
      .test(
        "is-unique-license-key",
        "License Key is already in use",
        (value) => {
          return allLicenses.every(
            (license) =>
              license.licenseKey !== value ||
              license.licenseName === currentLicenseName
          );
        }
      ),
    licenseConfigurationName: Yup.string()
      .nullable()
      .required("Configuration is required"),
    licenseType: Yup.number()
      .oneOf([0, 1, 2], "Invalid License type")
      .required("License type is required"),
    comment: Yup.string(),
    adminComment: Yup.string(),
    validFrom: Yup.date()
      .required("Valid From is required")
      .test(
        "is-before-license-valid-till",
        "Must precede the Valid Till",
        function (value) {
          const { validTill, licenseType } = this.parent;
          const licenseTypeNormalize = licenseTypes.find(
            (item) => item.value === licenseType
          ).label;
          const oneDayAfterValidTill = dayjs(validTill).add(12, "hour");
          return licenseTypeNormalize === LICENSE_TYPES.LIFETIME
            ? true
            : dayjs(value).isBefore(oneDayAfterValidTill);
        }
      ),
    validTill: Yup.date()
      .typeError("Choose date")
      .required("Required")
      .nullable(),
  });

  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [open]);

  return (
    <Formik
      initialValues={{
        licenseName: license.licenseName,
        licenseKey: license.licenseKey,
        licenseConfigurationName: assignConfigForLicense[0],
        licenseType: licenseTypes.find((item) => item.label === license.type)
          .value,
        validTill: license.validTill
          ? dayjs(license.validTill.toDate())
          : dayjs().toDate(),
        validFrom: license.validFrom
          ? dayjs(license.validFrom.toDate())
          : dayjs().toDate(),
        comment: license.comment || "",
        adminComment: license.adminComment || "",
      }}
      enableReinitialize={true}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validateOnBlur
      validateOnChange
    >
      {(props) => (
        <Form>
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>Edit License</DialogTitle>
            <DialogContent>
              <Stack
                direction="column"
                spacing={2}
                marginY={2}
                alignItems="start"
              >
                <FormikTextfield
                  name="licenseName"
                  fullWidth={true}
                  label="License Name"
                  variant="standard"
                />
                {isAdmin && (
                  <FormikTextfield
                    name="licenseKey"
                    fullWidth={true}
                    label="Key"
                    variant="standard"
                    onChange={(e) => 
                      props.setFieldValue("licenseKey", e.target.value.toUpperCase())
                    }
                  />
                )}
                <FormikSelect
                  name="licenseConfigurationName"
                  label="Configuration"
                  options={configurationsForSelectedGroup}
                />
                <FormikSelect
                  name="licenseType"
                  label="Type"
                  userTypeValue={
                    licenseTypes.find((item) => item.label === license.type)
                      .value
                  }
                  isAdmin={isAdmin}
                  options={licenseOrder}
                />
                <FormikDateField
                  name="validFrom"
                  label="Valid From"
                  format="YYYY-MM-DD"
                />
                <FormikDateField
                  name="validTill"
                  label="Valid Till"
                  format="YYYY-MM-DD"
                  disabled={props.values.licenseType === 2}
                />
                <FormikTextfield
                  name="comment"
                  fullWidth={true}
                  label="Comment"
                  variant="standard"
                  multiline
                />
                {isAdmin && (
                  <FormikTextfield
                    name="adminComment"
                    fullWidth={true}
                    label="Admin Comment"
                    variant="standard"
                    multiline
                  />
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button sx={{ color: "red" }} onClick={handleClose}>
                Cancel
              </Button>
              <FormikButton sx={{ color: "green" }} type="submit">
                Save
              </FormikButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default LicenseEditDialog;
