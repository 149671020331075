import { createSelector } from "reselect";

import { selectEnhancedUser, selectUserGroups } from "../user/user.selector";
import { selectGroups } from "../groups/groups.selector";
import { USER_ROLE } from "../../utils/helpers/constants";

const selectLicenseOverview = (state) => state.licenseOverview;
export const selectLicenseQuery = (state) => state.licenseOverview.searchString

export const selectLicenseOverviewGroupId = createSelector(
    [selectLicenseOverview],
    licenseOverview => licenseOverview.groupId
);

export const selectLicenseOverviewStatus = (state) => state.licenseOverview.statuses;
export const selectLicenseOverviewTypes = (state) => state.licenseOverview.types;
export const selectLicenseOverviewAppVersions = (state) => state.licenseOverview.versions;
export const selectLicenseOverviewSortBy = (state) => state.licenseOverview.sortBy

export const selectLicenseOverviewGroup = createSelector(
    [selectLicenseOverviewGroupId, selectUserGroups, selectEnhancedUser, selectGroups],
    (groupId, userGroups, user, allGroups) => {
        if (user && userGroups && groupId) {
            if (user.role === USER_ROLE.ADMIN) {
                return allGroups || [];
            } else {
                return userGroups
            }
        }
        return [];
    }
);

