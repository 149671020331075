import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Formik } from "formik";
import React, { Fragment, useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ClearIcon } from "@mui/x-date-pickers";
import { CONFIGURATION_PLATFORMS_TYPE, CONFIGURATION_SCREEN_SIZE } from "../../utils/helpers/constants";
import { FilterSelector } from "../filter/filter-select.component";

const ConfigurationSearchBar = ({
                                    handleSearch,
                                    query,
                                    selectedGroup,
                                    handleGroups,
                                    allGroups,
                                    selectedPlatform,
                                    handlePlatformChange,
                                    selectedScreenSize,
                                    handleScreenSizeChange,
                                    selectedLanguage,
                                    handleLanguageChange,
                                    selectedFeature,
                                    handleFeatureChange,
                                    selectedApp,
                                    handleAppChange,
                                    numberOfConfigurations,
                                    filteredConfig,
                                    screenSizes,
                                    apps,
                                    features,
                                    languages
                                }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    return (
    <Formik
      initialValues={{
        configurationsPlatform: selectedPlatform,
        configurationsScreenSize: selectedScreenSize,
        configurationsLanguage: selectedLanguage,
        configurationsFeature: selectedFeature,
        }}
        >
              <Fragment>
        
        <Box display="flex" flexDirection="column" gap="20px">
            {allGroups.length >= 1 && (
                <FormControl>
                    <InputLabel id="configuration-overview-select-label">Group</InputLabel>
                    <Select
                        labelId="configuration-overview-select-label"
                        label="Group"
                        value={selectedGroup}
                        onChange={handleGroups}
                        fullWidth={true}
                    >
                        {allGroups.map(({ id, groupName }) => (
                            <MenuItem key={id} value={id}>
                                {groupName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <TextField
                variant="outlined"
                value={query}
                onChange={(event) => handleSearch(event.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {query !== "" && (
                                <IconButton onClick={() => handleSearch("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <Box display="flex" flexDirection="column" gap="20px">
                <Box display="flex" flexDirection={windowWidth < 500 ? "column" : "row"} gap="20px">
                    {/* Platform filter */}
                    <Box sx={{ margin: 0, p: 0 }} width={"100%"} marginY={1}>
                        <FilterSelector
                            avalibleOptions={CONFIGURATION_PLATFORMS_TYPE}
                            filterName="Platform"
                            setFilterOptions={handlePlatformChange}
                            fieldName="configurationsPlatform"
                            multiple={true}
                        />
                    </Box>

                    {/* Screen Size filter */}
                    <Box sx={{ margin: 0, p: 0 }} width={"100%"} marginY={1}>
                        <FilterSelector
                            avalibleOptions={CONFIGURATION_SCREEN_SIZE}
                            filterName="Screen Size"
                            setFilterOptions={handleScreenSizeChange}
                            fieldName="configurationsScreenSize"
                            multiple={true}
                            getLabel={(option) => option?.label || ''}
                            getValue={(option) => option?.value}
                        />
                    </Box>

                    {/* Feature filter */}
                    <Box sx={{ margin: 0, p: 0 }} width={"100%"} marginY={1}>
                        <FilterSelector
                            avalibleOptions={features}
                            filterName="Feature"
                            setFilterOptions={handleFeatureChange}
                            fieldName="configurationsFeature"
                            multiple={true}
                            getLabel={(option) => option?.featureName || ''}
                            getValue={(option) => option?.id}
                        />
                    </Box>
                </Box>
                <Box display="flex" flexDirection={windowWidth < 500 ? "column" : "row"} gap="20px">
                    {/* Language filter */}
                    <Box sx={{ margin: 0, p: 0 }} width={"100%"} marginY={1}>
                        <FilterSelector
                            avalibleOptions={languages}
                            filterName="Language"
                            setFilterOptions={handleLanguageChange}
                            fieldName="configurationsLanguage"
                            multiple={true}
                            getLabel={(option) => option?.languageName || ''}
                            getValue={(option) => option?.id}
                        />
                    </Box>
                    {/* App filter */}
                    <Box sx={{ margin: 0, p: 0 }} width={"100%"} marginY={1}>
                        <FilterSelector
                            avalibleOptions={apps}
                            filterName="App"
                            setFilterOptions={handleAppChange}
                            fieldName="configurationsApp"
                            multiple={true}
                            getLabel={(option) => option?.appName || ''}
                            getValue={(option) => option?.id}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
            </Fragment>
        </Formik>
    );
};

export default ConfigurationSearchBar;
