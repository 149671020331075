import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PaymentsIcon from "@mui/icons-material/Payments";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ControllsDrawer from "./controlls-drawer.component";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { LICENSE_TYPES } from "../../../utils/helpers/constants";
import { COLOR_PURPLE } from "../../../utils/helpers/constants";

const LicenseListInfo = ({
  isOptionsVisible,
  license,
  isAdmin,
  isDisabledChip,
  isExpiredChip,
  isDeviceLinked,
  isPaymentRequiredChip,
  isValidityNotStartedChip,
  setIsDeleteDialogOpen,
  setIsDisableDialogOpen,
  setIsEditDialogOpen,
  setIsPaymentHistoryOpen,
  setIsChangeLogOpen,
  setIsUnlinkDialogOpen,
  isAdminOrEditor,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar("License key copied to clipboard!", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Failed to copy license key.", { variant: "error" });
      });
  };

  const renderChips = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      {isDisabledChip && (
        <Chip
          size="small"
          label={"Disabled"}
          sx={{
            color: "white",
            backgroundColor: "red",
            height: "18px",
            fontSize: "0.6rem",
            borderRadius: "16px",
            padding: "1px 1px",
          }}
        />
      )}
      {isExpiredChip && (
        <Chip
          size="small"
          label={"Validity Expired"}
          sx={{
            color: "white",
            backgroundColor: "orange",
            height: "18px",
            fontSize: "0.6rem",
            borderRadius: "16px",
            padding: "1px 1px",
          }}
        />
      )}
      {isValidityNotStartedChip && (
        <Chip
          size="small"
          label={"Validity Not Started"}
          sx={{
            color: "white",
            backgroundColor: "gray",
            height: "18px",
            fontSize: "0.6rem",
            borderRadius: "16px",
            padding: "1px 1px",
          }}
        />
      )}
      {!isDeviceLinked && (
        <Chip
          size="small"
          label={"Not Linked"}
          sx={{
            color: "white",
            backgroundColor: "gray",
            height: "18px",
            fontSize: "0.6rem",
            borderRadius: "16px",
            padding: "1px 1px",
          }}
        />
      )}
      {isPaymentRequiredChip && license.type !== LICENSE_TYPES.TRIAL && (
        <Chip
          size="small"
          label={"Payment Required"}
          sx={{
            color: "white",
            backgroundColor: "blue",
            height: "18px",
            fontSize: "0.6rem",
            borderRadius: "16px",
            padding: "1px 1px",
          }}
        />
      )}
    </Box>
  );

  return windowWidth > 900 ? (
    <Grid item xs={12} style={{ paddingTop: "5px" }}>
      <ListItem sx={{ pl: 0 }}>
        <ListItemAvatar>
          <Avatar
            sx={{
              backgroundColor: isOptionsVisible ? `${COLOR_PURPLE}` : "#bdbdbd",
            }}
          >
            {license.licenseName[0]}
          </Avatar>
        </ListItemAvatar>

        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <Box display="flex">
              <span style={{ marginRight: "10px" }}>{license.licenseName}</span>
              {renderChips()}
            </Box>

            <Tooltip title="Copy to clipboard">
              <Box
                display="flex"
                alignItems="center"
                onClick={() => handleCopyToClipboard(license.licenseKey)}
                sx={{
                  cursor: "pointer",
                  color: "gray",
                }}
              >
                <Typography>{license.licenseKey}</Typography>
                <IconButton
                  sx={{
                    marginLeft: "5px",
                    fontSize: "small",
                    padding: 0,
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="center">
            <Box display="flex" justifyContent="flex-end" gap="10px">
              <Tooltip title="Changelog">
                <IconButton
                  sx={{
                    backgroundColor: `${COLOR_PURPLE}`,
                    color: "white",
                    ":hover": {
                      color: `${COLOR_PURPLE}`,
                      backgroundColor: "white",
                    },
                  }}
                  onClick={() => setIsChangeLogOpen(true)}
                >
                  <ManageSearchIcon />
                </IconButton>
              </Tooltip>

              {license.type !== LICENSE_TYPES.TRIAL && (
                <Tooltip title="Payments">
                  <IconButton
                    sx={{
                      backgroundColor: `${COLOR_PURPLE}`,
                      color: "white",
                      ":hover": {
                        color: `${COLOR_PURPLE}`,
                        backgroundColor: "white",
                      },
                    }}
                    onClick={() => setIsPaymentHistoryOpen(true)}
                  >
                    <PaymentsIcon />
                  </IconButton>
                </Tooltip>
              )}

              {isAdminOrEditor && (
                <Tooltip title="Unlink Device">
                  <span>
                    <IconButton
                      sx={{
                        backgroundColor: `${COLOR_PURPLE}`,
                        color: "white",
                        ":hover": {
                          color: `${COLOR_PURPLE}`,
                          backgroundColor: "white",
                        },
                      }}
                      onClick={() => setIsUnlinkDialogOpen(true)}
                      disabled={license.hardwareIds.length === 0}
                    >
                      <LinkOffIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {isAdminOrEditor &&
                (!isDisabledChip ? (
                  <Tooltip title="Disable">
                    <IconButton
                      sx={{
                        backgroundColor: `${COLOR_PURPLE}`,
                        color: "white",
                        ":hover": { color: `${COLOR_PURPLE}` },
                      }}
                      onClick={() => setIsDisableDialogOpen(true)}
                    >
                      <StopCircleIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Enable">
                    <IconButton
                      sx={{
                        backgroundColor: `${COLOR_PURPLE}`,
                        color: "white",
                        ":hover": { color: `${COLOR_PURPLE}` },
                      }}
                      onClick={() => setIsDisableDialogOpen(true)}
                    >
                      <PlayCircleIcon />
                    </IconButton>
                  </Tooltip>
                ))}

              {isAdminOrEditor && (
                <Tooltip title="Edit">
                  <IconButton
                    sx={{
                      backgroundColor: `${COLOR_PURPLE}`,
                      color: "white",
                      ":hover": { color: `${COLOR_PURPLE}` },
                    }}
                    onClick={() => setIsEditDialogOpen(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isAdminOrEditor && (
                <Tooltip title="Delete">
                  <span>
                    <IconButton
                      color="error"
                      onClick={() => setIsDeleteDialogOpen(true)}
                      disabled={!isAdmin}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      </ListItem>
    </Grid>
  ) : (
    <Box width="100%" display="flex" gap="20px" justifyContent="space-between">
      <Box display="flex" padding="16px" flexDirection="column" gap="2px">
        <Box>{license.licenseName}</Box>
        {renderChips()}

        <Box display="flex" alignItems="center">
          <Tooltip title="Copy to clipboard">
            <Box
              onClick={() => handleCopyToClipboard(license.licenseKey)}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "gray",
              }}
            >
              <Typography sx={{ cursor: "pointer", color: "gray" }}>
                {license.licenseKey}
              </Typography>
              <IconButton
                sx={{
                  marginLeft: "5px",
                  fontSize: "small",
                  cursor: "pointer",
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        <ControllsDrawer
          license={license}
          isAdmin={isAdmin}
          isDisabledChip={isDisabledChip}
          setIsUnlinkDialogOpen={setIsUnlinkDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          setIsDisableDialogOpen={setIsDisableDialogOpen}
          setIsEditDialogOpen={setIsEditDialogOpen}
          setIsPaymentHistoryOpen={setIsPaymentHistoryOpen}
          setIsChangeLogOpen={setIsChangeLogOpen}
        />
      </Box>
    </Box>
  );
};

export default LicenseListInfo;
