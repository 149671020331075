import { useField, useFormikContext } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";

const FormikAutocomplete = ({
  name,
  label,
  options,
  handleGroups = () => {},
  multiple = false,
  getLabel = (option) => option,
  getValue = (option) => option,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField(name);
  const { values, setValues } = useFormikContext();

  const handleChange = (event, newValue) => {
    const formattedValue = multiple
      ? newValue.map((option) => getValue(option))
      : newValue
      ? getValue(newValue)
      : null;

    setValues({ ...values, [name]: formattedValue });
    handleGroups(newValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  const sortedOptions = [...options].sort((a, b) =>
    getLabel(a).localeCompare(getLabel(b))
  );

  const isOptionEqualToValue = (option, value) =>
    getValue(option) === value;

  const value = multiple
  ? (values[name] || []).map((val) =>
      options.find((opt) => getValue(opt) === val)
    )
  : options.find((opt) => getValue(opt) === values[name]) || null

  const getOptionLabel = (option) =>
    typeof option === "object" ? getLabel(option) : option

  return (
    <FormControl fullWidth error={meta.touched && !!meta.error}>
      <Autocomplete
        {...otherProps}
        name={field.name}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        value={value}
        options={sortedOptions}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple={multiple}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error ? meta.error : ""}
          />
        )}
      />
    </FormControl>
  );
};


export default FormikAutocomplete;
